$drawer-bg-color: #fff;
$drawer-border-color: #e0e0e0;
$drawer-heading-color: #757575;
$drawer-hover-color: #f0f0f0;

.mdl-layout__drawer {
  background-color: $drawer-bg-color;

  .mdl-layout-title {
    border-bottom: 1px solid $drawer-border-color;
    padding-left: 24px;

    a {
      text-decoration: none;
      color: $drawer-heading-color;
      font-weight: normal;
    }
  }
}

.drawer-nav {
  ul {
    list-style: none;
    padding-left: 0;

    li {
      display: block;
      padding: 0;

      ul {
        li {
          a {
            padding-left: 44px;
            font-weight: 400;
          }
        }
      }

      span.drawer-nav-title {
        display: block;
        flex-shrink: 0;
        padding: 15px 0 15px 22px;
        margin: 0;
        font-weight: 600;
        color: $drawer-heading-color;
        line-height: 1em;
        text-decoration: none;
      }

      a {
        display: block;
        flex-shrink: 0;
        padding: 15px 0 15px 22px;
        margin: 0;
        font-weight: 600;
        color: $drawer-heading-color;
        line-height: 1em;
        text-decoration: none;
        cursor: pointer;

        &:active,
        &:hover {
          background-color: $drawer-hover-color;
        }
      }

      &.active {
        a {
          color: $primary-color;
          font-weight: 500;
        }
      }
    }
  }
}
