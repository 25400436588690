$primary-color: rgb(33, 150, 243);
$body-bg-color: #fafafa;

body {
  background-color: $body-bg-color;
}

img.responsive {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

.mdl-layout__content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-flow: column;
  flex-direction: column;
}

.mdl-layout__content > *:not(.mdl-layout-spacer) {
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

$scroll-to-top-color: #fff;

footer {
  #scroll-to-top {
    color: $scroll-to-top-color;
  }
}
