$hero-bg-color: rgb(33, 150, 243);
$hero-color: #fff;

.hero {
  background-color: $hero-bg-color;
  color: $hero-color;
  padding-top: 40px;
  padding-bottom: 20px;

  .mdl-cell {
    text-align: center;
  }

  h1 {
    font-size: 32px;
    font-weight: 300;
  }

  p.lead {
    font-size: 18px;
    font-weight: 300;
  }

  .mdl-button.mdl-button--accent {
    margin-top: 10px;
    background-color: $body-bg-color;
  }
}

.feature {
  padding: 20px 0;

  h1 {
    font-size: 28px;
    font-weight: 300;
  }
}
